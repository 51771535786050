<template>
    <el-drawer
      :model-value="visible"
      title="历史订单查询"
      direction="rtl"
      @update:model-value="handleClose"
    >
      <div class="history-content">
        <div class="query-method">
          <el-radio-group v-model="queryMethod" class="query-method-group">
            <el-radio-button label="域名 & 邮箱">域名 & 邮箱</el-radio-button>
          </el-radio-group>
        </div>
        <div class="input-fields" v-if="queryMethod === '域名 & 邮箱'">
          <el-input
            v-model="domain"
            placeholder="请输入创建订单时输入的完整域名"
            class="custom-input"
          ></el-input>
          <el-input
            v-model="email"
            placeholder="请输入创建订单时输入的邮箱"
            class="custom-input"
          ></el-input>
        </div>
        <div class="buttons">
          <el-button @click="handleCancel">取消</el-button>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </div>
        <div v-if="queryResult" class="query-result">
          <h3>订单信息</h3>
          <div class="order-info">
            <p>订单号: <span>{{ queryResult.order_number }}</span></p>
            <p>申请产品: <span>{{ queryResult.applied_product }}</span></p>
            <p>申请域名: <span>{{ queryResult.applied_domain }}</span></p>
            <p>签发状态: <span class="status">{{ queryResult.status }}</span></p>
          </div>
  
          <a
            v-if="queryResult.status === '签发成功'"
            :href="queryResult.dow"
            download
            class="download-link"
          >
            下载证书
          </a>
  
          <el-button
            v-if="queryResult.status === '待申请' || queryResult.status === '待验证'"
            @click="openProductDrawer(queryResult)"
            type="primary"
          >
            开始验证
          </el-button>
        </div>
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </div>
  
      <component
        :is="productDrawerComponent"
        v-model:visible="productDrawerVisible"
        :product="selectedProduct"
        :domain="domain"
        :order-number="selectedOrderNumber"
        :step="selectedStep"
      />
    </el-drawer>
  </template>
  
  <script>
  import { ref } from 'vue';
  import api from '@/api';
  import ProductDrawerDj from '@/components/ProductDrawerDj.vue';
  import ProductDrawerRo from '@/components/ProductDrawerRo.vue';
  import ProductDrawerRojoy from '@/components/ProductDrawerRojoy.vue';
  
  export default {
    name: 'HistoryOrderDrawer',
    components: {
      ProductDrawerDj,
      ProductDrawerRo,
      ProductDrawerRojoy,
    },
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    setup(props, { emit }) {
      const queryMethod = ref('域名 & 邮箱');
      const domain = ref('');
      const email = ref('');
      const queryResult = ref(null);
      const errorMessage = ref('');
      const productDrawerVisible = ref(false);
      const selectedOrderNumber = ref('');
      const selectedProduct = ref({});
      const selectedStep = ref(0);
      const productDrawerComponent = ref('ProductDrawerDj');
  
      const handleClose = (newVisibleValue) => {
        emit('update:visible', newVisibleValue);
      };
  
      const handleCancel = () => {
        emit('update:visible', false);
        resetQueryState();
      };
  
      const handleQuery = async () => {
        try {
          const response = await api.searchOrderHistory(domain.value, email.value);
          console.log('Order History Response:', response);
          if (response.status === 'success') {
            queryResult.value = response.data;
            errorMessage.value = '';
          } else {
            alert(response.message || '没有找到匹配的订单');
          }
        } catch (error) {
          console.error('查询订单历史错误:', error);
          errorMessage.value = '查询订单历史失败，请稍后重试';
        }
      };
  
  

  const openProductDrawer = (orderData) => {
    selectedOrderNumber.value = orderData.order_number;
    selectedProduct.value = orderData.applied_product;
    selectedStep.value = 1;

    // Modify conditions to open appropriate drawers
    if (orderData.ssl_product_id === '197') {
        productDrawerComponent.value = 'ProductDrawerDj';
    } else if (
        orderData.ssl_product_id === 'comodo-positivessl' ||
        orderData.ssl_product_id === 'comodo-positivessl-wildcard'
    ) {
        productDrawerComponent.value = 'ProductDrawerRo';
    } else if (orderData.ssl_product_id === 'joyssl-dv-single-agent') {
        productDrawerComponent.value = 'ProductDrawerRojoy';
    }

    productDrawerVisible.value = true;
};




      const resetQueryState = () => {
        queryResult.value = null;
        errorMessage.value = '';
        domain.value = '';
        email.value = '';
      };
  
      return {
        queryMethod,
        domain,
        email,
        queryResult,
        errorMessage,
        handleClose,
        handleCancel,
        handleQuery,
        openProductDrawer,
        productDrawerVisible,
        selectedOrderNumber,
        selectedProduct,
        selectedStep,
        productDrawerComponent,
      };
    },
  };
  </script>
  
  <style scoped>
  .history-content {
    padding: 20px;
  }
  
  .query-method {
    margin-bottom: 20px;
  }
  
  .query-method-group {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .input-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .custom-input >>> .el-input__inner {
    border-radius: 4px;
    height: 40px;
    padding: 0 12px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .el-button {
    width: 80px;
  }
  
  .query-result {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .order-info p {
    font-size: 14px;
    color: #333;
    margin: 5px 0;
  }
  
  .order-info span {
    font-weight: bold;
  }
  
  .status {
    color: green;
  }
  
  .error-message {
    color: red;
    margin-top: 20px;
  }
  </style>
  