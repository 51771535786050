<template>
  <el-drawer v-model="localVisible" direction="rtl" size="30%" :append-to-body="true" @close="handleClose">
    <template #header>
      <div class="header-content">
        <h2 class="drawer-title">生成SSL证书1</h2>
      </div>
    </template>

    <hr class="custom-hr" />

    <div class="drawer-content">
      <div class="custom-steps">
        <div class="step" :class="{ active: activeStep === 0 }">
          <span class="circle">1</span>
          <span class="label">确认订单</span>
        </div>
        <div class="line"></div>
        <div class="step" :class="{ active: activeStep === 1 }">
          <span class="circle">2</span>
          <span class="label">支付订单</span>
        </div>
        <div class="line"></div>
        <div class="step" :class="{ active: activeStep === 2 || activeStep === 3 }">
          <span class="circle">3</span>
          <span class="label">生成SSL</span>
        </div>
      </div>

      <div class="order-details" v-if="activeStep === 0">
        <div class="order-item">
          <span class="label">域名</span>
          <span class="value">{{ domain }}</span>
        </div>
        <div class="order-item">
          <span class="label">证书品牌</span>
          <span class="value">{{ product.brand }}</span>
        </div>
        <div class="order-item">
          <span class="label">证书类型</span>
          <span class="value">{{ product.ssl_type }}</span>
        </div>
        <div class="order-item">
          <span class="label">域名数量</span>
          <span class="value">{{ product.number == 1 ? '单域名' : '通配符' }}</span>
        </div>
        <div class="order-item">
          <span class="label">证书时长</span>
          <span class="value">{{ product.certificate_duration }} 年</span>
        </div>
        <div class="order-item">
          <span class="label">价格</span>
          <span class="value" style="color: red;">¥{{ product.price }}</span>
        </div>
        <div class="order-item">
          <span class="label required">邮箱</span>
          <el-input v-model="email" placeholder="输入你的邮箱" class="order-input" />
        </div>
        <!--<div class="order-item">
          <span class="label">激活码</span>
          <el-input v-model="activationCode" placeholder="可输入从其他渠道获取的激活码" class="order-input" />
        </div>
        <div class="activation-note">
          使用激活码时请确认所选产品是否一致
        </div>-->
      </div>
      <div class="order-tip" v-if="activeStep === 0">
        <el-alert type="info" show-icon>
          请再次确认域名是否正确
        </el-alert>
      </div>
      <div class="order-actions" v-if="activeStep === 0">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="nextStep">去支付</el-button>
      </div>

      <div v-if="activeStep === 1" class="payment-step">
        <el-alert type="success" title="订单已创建" class="payment-stepb" description="请使用微信扫码支付" show-icon />

        <div class="order-item">
          <span class="label">订单号</span>
          <span class="value">{{ orderNumber }}</span>
        </div>
        <div class="order-item">
          <span class="label">订单金额</span>
          <span class="value">¥{{ product.price }}</span>
        </div>
        <div class="order-item">
          <span class="label">支付方式</span>
          <span class="value">微信支付</span>
        </div>
        <div class="qrcode-container">
          <qrcode-vue :value="wechatPayUrl" :size="200" />
        </div>
        <el-button type="primary" @click="confirmPayment">确认支付</el-button>
      </div>

      <div v-if="activeStep === 2" class="ssl-generation-step">
  <el-alert type="success" title="支付成功" description="正在生成SSL证书..." show-icon />
  <div v-if="orderDetails" class="order-info">
    <h3 class="order-title">订单信息</h3>
    <div class="order-item">
      <span class="label">订单号</span>
      <span class="value">{{ orderDetails.order_number }}</span>
    </div>
    <div class="order-item">
      <span class="label">申请产品</span>
      <span class="value">{{ orderDetails.applied_product }}</span>
    </div>
    <div class="order-item">
      <span class="label">域名</span>
      <span class="value">{{ orderDetails.applied_domain }}</span>
    </div>
    <div class="order-item">
      <span class="label">签发状态</span>
      <span class="value">{{ orderDetails.status === 5 ? '已签发' : orderDetails.status === 3 ? '待验证域名' : '未签发' }}</span>
    </div>
  </div>


  <div v-if="domainList.length > 0" class="validation-section">
          <h3 class="validation-title">验证信息</h3>
          <span class="validation-link" @click="handleVerifyAll">已配置，立即验证</span>
        </div>
  <div v-if="domainList.length > 0" class="validation-info">
    <div v-for="domain in domainList" :key="domain.DCVdnsHost" class="domain-info">
      <div class="order-item">
        <span class="label">验证方式</span>
        <span class="value"><!--{{ domain.DCVdnsType }}-->DNS</span>
      </div>
      <div class="order-item">
        <span class="label">主机记录</span>
        <span class="value">{{ domain.DCVdnsHost }}</span>
        <el-button class="copy-btn" @click="copyToClipboard(domain.DCVdnsHost)">复制</el-button>
      </div>
      <div class="order-item">
        <span class="label">记录类型</span>
        <span class="value">{{ domain.DCVdnsType }}</span>
      </div>
      <div class="order-item">
        <span class="label no-wrap">记录值</span>
        <span class="value word-break highlight">{{ domain.DCVdnsValue }}</span>
        <el-button class="copy-btn" @click="copyToClipboard(domain.DCVdnsValue)">复制</el-button>

      </div>
    </div>
    <el-button type="primary" @click="copyAllToClipboard">一键复制所有内容</el-button>

  </div>
</div>

<div v-if="activeStep === 3" class="ssl-download-step">
        <div class="order-info">
          <h3 class="order-title">订单信息</h3>
          <div class="order-item">
            <span class="label">订单号</span>
            <span class="value">{{ orderDetails.order_number }}</span>
          </div>
          <div class="order-item">
            <span class="label">申请产品</span>
            <span class="value">{{ orderDetails.applied_product }}</span>
          </div>
          <div class="order-item">
            <span class="label">域名</span>
            <span class="value">{{ orderDetails.applied_domain }}</span>
          </div>
          <div class="order-item">
            <span class="label">签发状态</span>
            <span class="value success">{{ orderDetails.status === 5 ? '已签发' : '未签发' }}</span>
          </div>
        </div>
        <a :href="orderDetails.downloadLink" download class="download-link">已签发！ 直接下载证书</a>

      </div>

    </div>
  </el-drawer>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';  // 引入 useStore 来访问 Vuex
import QrcodeVue from 'qrcode.vue';
import api from '@/api';
import { ElLoading } from 'element-plus';
import { ElMessage } from 'element-plus';

export default {
  name: 'ProductDrawer',
  components: {
    QrcodeVue,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localVisible = ref(props.visible);
    const email = ref('');
    const activationCode = ref('');
    const activeStep = ref(0);
    const wechatPayUrl = ref('');
    const orderNumber = ref('');
    const domainList = ref([]);
    const orderDetails = ref(null);
    let loadingInstance = null;

    const handleClose = () => {
      localVisible.value = false;
    };

    const nextStep = async () => {
      if (activeStep.value === 0) {
        if (!email.value) {
          alert('请输入邮箱');
          return;
        }

        try {
          const orderData = {
            product_id: props.product.id,
            domain: props.domain,
            email: email.value,
          };
          const response = await api.rssCreateOrder(orderData);
          console.log('API Response:', response);
          if (response.status === 'success' && response.wechatPayUrl) {
            wechatPayUrl.value = response.wechatPayUrl;
            orderNumber.value = response.data.order_number;
            orderDetails.value = response.data;
            console.log('WeChat Pay URL:', wechatPayUrl.value);
            pollOrderStatus(); // Start polling for order status
          } else {
            console.error('Invalid response structure:', response);
          }
          activeStep.value++;
        } catch (error) {
          console.error('提交订单错误:', error);
        }
      } else if (activeStep.value < 2) {
        activeStep.value++;
      }
    };

    const pollOrderStatus = async () => {
      const intervalId = setInterval(async () => {
        try {
          const response = await api.rssCheckOrderStatus(orderNumber.value);
          console.log('Polling Order Status Response:', response);
          if (response.status === 'success') {
            clearInterval(intervalId);
            activeStep.value++;
            await handleOrderValidation(); // Proceed to handle order validation
          }
        } catch (error) {
          console.error('查询订单状态错误:', error);
        }
      }, 5000); // Check every 5 seconds
    };

    const confirmPayment = async () => {
  try {
    const response = await api.rssCheckOrderStatus(orderNumber.value);
    console.log('Order Status Response:', response);
    if (response.status === 'success') {
      activeStep.value = 2;  // 确保在这里更新为2
      await handleOrderValidation();
    } else {
      alert(response.message || '支付未完成');
    }
  } catch (error) {
    console.error('查询订单状态错误:', error);
  }
};

/*
const handleOrderValidation = async () => {
  try {
    const response = await api.rssGenerateCertificate({
      order_number: orderNumber.value,
      domain: props.domain,
      product_id: props.product.ssl_product_id,
      email: email.value,
    });

    console.log('Order Validation Response:', response); // 确保响应包含正确的结构

    if (response.status === 'success') {
      const domainListData = response.domainList; // 确保这是一个对象
      if (domainListData) {
        // 将 domainList 对象的单个元素转换为数组，方便使用 v-for 迭代
        domainList.value = [domainListData]; 
        orderDetails.value = response.orderDetails;
        activeStep.value = 2; // 确保在这里更新为2，显示SSL生成步骤
      } else {
        console.error('Domain list is not available:', domainListData);
      }
    } else {
      console.error('订单验证失败:', response.message);
    }
  } catch (error) {
    console.error('订单验证错误:', error);
  }
};
*/

const handleOrderValidation = async () => {
  // Start the loading animation
  loadingInstance = ElLoading.service({
    lock: true,
    text: "正在验证订单...",
    background: "rgba(0, 0, 0, 0.7)",
  });

  try {
    const response = await api.rssGenerateCertificate({
      order_number: orderNumber.value,
      domain: props.domain,
      product_id: props.product.ssl_product_id,
      email: email.value,
    });

    console.log('Order Validation Response:', response); // 确保响应包含正确的结构

    if (response.status === 'success') {
      const domainListData = response.domainList; // 确保这是一个对象
      if (domainListData) {
        // 将 domainList 对象的单个元素转换为数组，方便使用 v-for 迭代
        domainList.value = [domainListData];
        orderDetails.value = response.orderDetails;
        activeStep.value = 2; // 确保在这里更新为2，显示SSL生成步骤
      } else {
        console.error('Domain list is not available:', domainListData);
      }
    } else {
      console.error('订单验证失败:', response.message);
    }
  } catch (error) {
    console.error('订单验证错误:', error);
  } finally {
    // Close the loading animation
    loadingInstance.close();
  }
};





const handleVerifyAll = async () => {
  try {
    loadingInstance = ElLoading.service({
      lock: true,
      text: "正在验证域名...",
      background: "rgba(0, 0, 0, 0.7)",
    });
    const response = await api.rssVerifyDomain({
      order_number: orderDetails.value.order_number, // 使用order_number
    });
    loadingInstance.close();

    // 无论验证成功还是失败都继续下载证书
    await downloadCertificate(response.status !== 'success');
  } catch (error) {
    loadingInstance.close();
    console.error("验证域名错误:", error);
    alert("验证域名过程中出错，请重试");
  }
};


// 下载证书的功能
const downloadCertificate = async (isVerificationFailed) => {
  try {
    const response = await api.rssCollect({
      order_number: orderDetails.value.order_number, // 使用 order_number 字段
    });

    if (response.status === "success" && response.message === "证书已收集并打包") {
      console.log("Download Link:", response.downloadLink); // 打印出下载链接以调试
      orderDetails.value.downloadLink = response.downloadLink;
      orderDetails.value.status = 5; // 标记为已签发
      activeStep.value = 3; // 进入下载步骤
    } else {
      if (isVerificationFailed) {
        alert("域名验证失败，请重试");
      } else {
        alert("证书下发失败，请重新验证");
      }
      activeStep.value = 2; // 返回到验证步骤
    }
  } catch (error) {
    console.error("下载证书错误:", error);
    alert("下载证书过程中出错，请重试");
    activeStep.value = 2; // 返回到验证步骤
  }
};


const copyToClipboard = (text) => {
  try {
    // 创建一个隐藏的 textarea 元素
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';  // 防止页面滚动
    textarea.style.opacity = '0';  // 隐藏元素

    document.body.appendChild(textarea);
    textarea.select();

    // 执行复制命令
    document.execCommand('copy');
    document.body.removeChild(textarea);  // 复制完成后移除元素

    ElMessage({
      message: '复制成功！',
      type: 'success',
    });
  } catch (err) {
    ElMessage({
      message: '复制失败，请重试。',
      type: 'error',
    });
    console.error('复制失败:', err);
  }
};

// 一键复制所有内容
const copyAllToClipboard = () => {
      try {
        let allText = '';

        domainList.value.forEach((domain) => {
          allText += `主机记录: ${domain.DCVdnsHost}\n`;
          allText += `记录类型: ${domain.DCVdnsType}\n`;
          allText += `记录值: ${domain.DCVdnsValue}\n\n`;
        });

        const textarea = document.createElement('textarea');
        textarea.value = allText;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';

        document.body.appendChild(textarea);
        textarea.select();

        document.execCommand('copy');
        document.body.removeChild(textarea);

        ElMessage({
          message: '所有内容已复制！',
          type: 'success',
        });
      } catch (err) {
        ElMessage({
          message: '复制失败，请重试。',
          type: 'error',
        });
        console.error('复制失败:', err);
      }
    };


    const store = useStore();  // 使用 Vuex Store
// 从 Vuex 中获取当前用户信息
const user = computed(() => store.getters.user);


// 打印用户信息，确认是否从 Vuex 获取到了用户邮箱
console.log('User from Vuex:', user.value);

// 在页面加载时，检查用户是否登录，并自动填充邮箱
onMounted(() => {
  if (user.value && user.value.email) {
    email.value = user.value.email; // 如果用户数据存在，填充邮箱
  }
});

// 监听 `user` 的变化，确保响应性
watch(user, (newUser) => {
  if (newUser && newUser.email) {
    email.value = newUser.email;
  }
});




    return {
      localVisible,
      email,
      activationCode,
      activeStep,
      wechatPayUrl,
      orderNumber,
      domainList,
      orderDetails,
      handleClose,
      nextStep,
      confirmPayment,
      handleVerifyAll,
      copyToClipboard,
      copyAllToClipboard,
      user
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val;
    },
    localVisible(val) {
      this.$emit('update:visible', val);
    },
  },
};
</script>

<style scoped>
.custom-hr {
  border: 0;
  height: 1px;
  background-color: #ececec;
  margin-bottom: 1px;
  margin-top: -20px;
}

.drawer-content {
  padding: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.drawer-title {
  margin: 0;
  font-size: 18px;
  text-align: left;
  font-weight: normal;
}

.custom-steps {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 4px;
}

.custom-steps .step {
  display: flex;
  align-items: center;
}

.custom-steps .step .circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 8px;
}

.custom-steps .step.active .circle {
  background-color: #409eff;
}

.custom-steps .step .label {
  font-size: 14px;
  color: #606266;
  font-weight: normal;
}

.custom-steps .line {
  flex: 1;
  height: 1px;
  background-color: #d9d9d9;
  margin: 0 8px;
}

.order-details {
  margin-bottom: 20px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.order-item .label {
  font-weight: normal;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 14px;
}

.order-item .label.required::before {
  content: '*';
  color: red;
  margin-right: 4px;
}

.order-item .value {
  color: #606266;
  font-weight: normal;
  font-size: 14px;
}

.order-item .value.word-break {
  word-wrap: break-word;
  word-break: break-all;
  background-color: #f0f0f0;
  padding: 5px;
}

.order-input {
  width: 300px;
  margin-bottom: 5px;
}

.activation-note {
  font-size: 12px;
  color: #909399;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
}

.order-tip {
  margin-bottom: 20px;
}

.order-actions {
  display: flex;
  justify-content: space-between;
}

.drawer-title {
  font-weight: normal;
}

.payment-stepb {
  margin-bottom: 20px;
}

.payment-step .qrcode-container {
  text-align: center;
  margin: 20px 0;
}

.payment-step .qrcode {
  width: 200px;
  height: 200px;
}

.order-title {
  font-weight: normal;
  font-size: 14px;
}

.order-info {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.validation-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

.validation-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  font-size: 14px;
}

.validation-link {
  cursor: pointer;
  color: #409eff;
  text-decoration: underline;
  font-size: 14px;
}

.validation-info {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

.domain-info {
  margin-bottom: 20px;
}

.ssl-download-step {
  text-align: left;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.ssl-download-step a {
  font-weight: bold;
  color: #409eff;
  text-decoration: underline;
}

.order-item .value.success {
  color: green;
}

.download-link {
  display: inline-block;
  color: #409eff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
}

.download-link:hover {
  text-decoration: underline;
}

.copy-btn {
  margin-left: 10px;
}

</style>
