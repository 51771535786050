// store/index.js
import { createStore } from 'vuex';

export default createStore({
    state: {
        user: null, // 存储用户信息
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        clearUser(state) {
            state.user = null;
        },
    },
    actions: {
        login({ commit }, user) {
            commit('setUser', user); // 触发登录，保存用户信息
        },
        logout({ commit }) {
            commit('clearUser'); // 触发登出，清除用户信息
        },
    },
    getters: {
        isLoggedIn: (state) => !!state.user, // 判断是否已登录
        user: (state) => state.user, // 获取用户信息
    },
});